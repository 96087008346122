import React, { useEffect } from 'react'
// import ReactPlayer from 'react-player'
// import Assets from '../../assets/_index'

const Newsletter = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Newsletter'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    
    <>
      <section className='breadcrumb blog_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='breadcrumb_iner'>
                <div className='breadcrumb_iner_item'>
                  <h2>Newsletter</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='contact-section area-padding'> */}
        <div className='container'>
          {/* <div className='text-center mb-3'> */}
              <h5 className='mb-5 '>MARELCO Newsletter 2024 Issue 2 (July-December 2024) </h5>
          {/* </div> */}
        </div>
      {/* </section> */}

     
    
    <section className='container-xxl bg-light'>
      <div className='container-history'>
        <div className='container box_1170'>
          <h3 className='text-heading history-title wow fadeInUp' data-wow-delay='0.1s'>NASAAN NA NGA BA ANG MARELCO PAGDATING SA PAGPAPABUTI SA DALOY NG KURYENTE? </h3>
          <img src='../resources/img/newsletter/img1.png' alt='MARELCO'></img>
          <p className='history-content wow fadeInUp mt-2' data-wow-delay='0.1s'>
            Ang MARELCO, bilang isang distributor ng kuryente ay may kontrata sa National Power Corporation o NAPOCOR. Ang NAPOCOR ay isang generation company o kumpanya 
            na gumagawa ng kuryente gamit ang diesel power plants na s’ya namang isinusuplay sa MARELCO. Ang NAPOCOR ay mayroong  apat na diesel power plants na nagge-generate
            ng kuryente; isa sa Bantad, Boac, isa sa  Cagpo, Torrijos, isa sa Balanacan, Mogpog o ang tinatawag na Power Barge 120 at may isang temporary na planta sa Buyabod, 
            Sta. Cruz na tinatawag namang Power Barge 113. Nitong mga nakalipas na buwan, malaki ang itinaas ng heat index kung kaya’t tuloy-tuloy ang pag-andar ng mga makina 
            ng NAPOCOR at nawalan ito ng pagkakataon para ma-maintenance;   ito ang naging pangunahing dahilan para magkaroon ng maraming aberya sa kanilang mga makina. Dahil rin dito, nagkulang ang kapasidad ng NAPOCOR upang mapunan ang pangangailangan ng lalawigan. Nagresulta pa ito sa pagsasagawa ng Load Shedding sa buong lalawigan 
            upang pagkasyahin ang noo’y 14.00 MW na kapasidad ng NAPOCOR laban sa 16.00 MW na pangangailangan ng lalawigan. 
            
            <button className='btn' type='button' data-toggle='collapse' data-target='#collapseTopic1' aria-expanded='false' aria-controls='collapseTopic1'>
            <p>Ipagpatuloy ang pagbabasa</p>
          </button></p>
          <br/>
          <div id='collapseTopic1' className='collapse hide'>
          <p className='history-content wow fadeInUp' data-wow-delay='0.1s'>
            Inulan ng sari-saring batikos, argumento, panlalait, 
            haka-haka at dismaya ang pangyayaring ito at isa sa mga kadalasang nababanggit ay ano ba ang plano ng MARELCO? Isa sa  pinakamalaking proyekto na hinihintay upang 
            mas mapabuti ang serbisyo ng kuryente ay ang interconnection ng ating probinsya sa Luzon Grid sa pamamagitan ng submarine cable na dadaan sa Quezon Province. Ang 
            nasabing proyektong ito ay naaprubahan na ng ERC na may kabuuang 5.2 bilyon pondo at inaasahang matatapos sa taong 2029. Maliban dito, nagsasagawa na rin ng mga 
            hakbang ang pamunuan para sa pagtatayo ng Renewable Energy. Ang mga ito ay nangangailangan ng masinsinang atensyon at pag-aaral kaya hindi agad-agad maisasakatuparan.
              <br /> <br/>
                Sa ngayon, habang nasa proseso pa ang mga ito, patuloy ang NAPOCOR sa pagdagdag ng mga makina upang magkaroon ng malaking reserba sakaling may mga makina na
                nangangailangan ng maintenance. Gayundin, patuloy ang kanilang pagtatayo ng mga 69kV Transmission Lines upang maging balanse ang boltahe na dumarating sa ating
                kabahayan lalo na sa mga areas na nasa load end. Ang MARELCO naman ay patuloy rin sa pagsasagawa ng mga maintenance activities katulad ng line clearing, 
                pagpapalit ng fiber crossarm sa mga wooden at steel crossarm at pagpapalit sa mga bare conductor ng insulated conductor. Layunin ng mga ito na mabawasan ang 
                mga unscheduled power interruption dulot ng mga sanga ng puno, palapa ng niyog, tuko, paniki, ibon, ahas, at iba pa. Ang fiber crossarm at ang insulated conductor
                ay hindi na electrically conductive kaya kahit may mga hayop na dumapo at sanga ng puno na mapadikit sa linya at insulators ay hindi na makakasanhi ng short 
                circuit at hindi na magdudulot ng biglaang pagkawala ng kuryente. Patuloy rin ang pagpapalit o reconductoring ng mga linya, transformer uprating at load 
                centering upang maisaayos ang boltahe dulot ng patuloy na pagdami ng populasyon at paglago ng ekonomiya ng probinsya.
            </p>
            <br />
            <p className='history-content wow fadeInUp' data-wow-delay='0.1s'>
                Ang mga nabanggit na aktibidad upang mapanatili ang maayos na daloy ng kuryente ay unti-unting isinasagawa dahil sa kakulangan sa pondo. Ang mga ibinabayad naman
                ng mga Member-Consumers ay hindi rin sapat upang mailaan sa mga proyektong ito dahil ibinabayad rin lamang ito sa NAPOCOR. Kaya paunti-unti, ginagawan ito ng paraan
                upang maisakatuparan. Hangga’t nasa proseso ang pagsa-sagawa nito, asahan na magkakaroon pa rin ng mga panaka-nakang power interruption. Maliban dito, naluluma ang
                ating mga electrical facilities kaya asahan pa rin na may mga biglaang masisira. May mga road accidents rin na wala namang may gustong mangyari at hindi maiwasang
                hindi makaapekto sa daloy ng kuryente. Hindi pa rin maiiwasan na hindi magtakda o mag-schedule ng power interruption upang magsagawa ng mga maintenance activity para
                sa mas ikagaganda ng serbisyo. Walang may gusto na mangyari ang mga brownout na ito at walang napapala ang MARELCO sa mga ito kaya hindi ito sinasadya. Kung tutuusin,
                napakalaki ng nawawala sa kooperatiba sa tuwing mawawala ang daloy ng kuryente. Kung kaya, bagama’t mahirap pa ring maintindihan ang pangunahing hinaing kung kailan
                ba matatapos ang mga brownout, doble ang pagsusumikap ng pamunuan sa paghahanap ng solusyon sa mga problemang ito upang maibigay ang maayos na serbisyo para sa mga
                MCOs.      
            </p>
          </div>
        </div>
      </div>

      <div className='container-history'>
        <div className='container box_1170'>
          <h3 className='text-heading history-title wow fadeInUp' data-wow-delay='0.1s'>2024 NEA — PHILRECA  — EC CONVERGENCE    </h3>
          <img src='../resources/img/newsletter/img3.png'alt='NEA-PHILRECA AWARDS'></img>
          <p className='history-content wow fadeInUp mt-2' data-wow-delay='0.1s'>
              Taon-taon isinasagawa ang isang Convergence sa pamamagitan ng Philippine Rural Electric Cooperatives Association (PHILRECA) at National Electrification 
              Administration (NEA). Ito ay dinadaluhan ng lahat ng mga Electric Cooperatives sa buong Pilipinas. Sa pagtitipon na ito isinasagawa ang paggawad ng mga parangal
              sa mga Electric Cooperatives na kaakibat ng   kanilang mga pagsusumikap upang matamo ang mga kategorya na inilathala ng PHILRECA at NEA bilang sukatan ng kanilang
              katapatan at maayos na pangkalahatang serbisyo.
              <br/>  <br/>
              Narito ang mga listahan ng mga awards na nakuha ng MARELCO sa nagdaang NEA-PHILRECA Convergence noong Agosto 29 - 31, 2024 sa PICC Pasay, City. 
            
            <button className='btn' type='button' data-toggle='collapse' data-target='#collapseTopic2' aria-expanded='false' aria-controls='collapseTopic2'>
              <p>Ipagpatuloy ang pagbabasa</p>
            </button>
          </p>
              <br/>
            <div id='collapseTopic2' className='collapse hide'>
              <div className='row'>
                <div className='col'>
                 
                  <p className='history-content wow fadeInUp text-center'  data-wow-delay='0.1s'> <strong>NEA 2024 Lumens Awards (National Electrification Administration) </strong></p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   1. ECs WITH ADVANCE PAYMENT TO NEA </p>  
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>     2. ECs WITH FULLY LIQUIDATED SUBSIDY FUND </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>     3. BEST COLLECTION EFFICIENCY </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>      4. REMARKABLE CORPORATE AND COMMUNITY PROGRAMS </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>    5. SINGLE DIGIT FEEDER LOSS AWARD </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>    6. TOP PERFORMING EC</p>
                  <img className='mt-2 center ' src='../resources/img/newsletter/img4.png' alt='NEA AWARDS'></img>
              <br/>
              </div>
              {/* <div className='row'> */}
                <div className='col'>
                  <p className='history-content wow fadeInUp text-center'  data-wow-delay='0.1s'> <strong>PHILRECA 2024 Awards from the Wires</strong></p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   1. BRIGHT BEGINNING AWARD  </p>  
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>    2. PLATINUM STELLAR AWARD </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>    3. MCO PRIME PARTICIPATION AWARD</p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>    4. MODEL MEMBER-EC AWARD </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>    5. PROMPT PAYOR AWARD </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>    6. INFORMATION EMPOWERMENT CATALYST AWARD</p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   7. OUTSTANDING PERFORMANCE AWARD </p>  
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   8. STATE OF THE ART WORKPLACE AWARD </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   9. ABOVE AND BEYOND AWARD</p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   10. OUTSTANDING RADIO PROGRAM EXCELLENCE AWARD </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   11. MCO CHAMPION AWARD </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   12. OCCUPATIONAL SAFETY AND HEALTH EXCELLENCE AWARD</p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   13. IRON PILLAR AWARD </p>  
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   14. KEY CONTRIBUTOR AWARD </p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   15. UNPARALLELED SERVICE AWARD</p>
                  <p className='history-content wow fadeInUp text-center' data-wow-delay='0.1s'>   16. LIMELIGHT AWARD </p>
              </div></div>
            </div>
           {/* <br /> */}
        </div>
      </div>

      <div className='container-history'>
        <div className='container box_1170'>
          <h3 className='text-heading history-title wow fadeInUp' data-wow-delay='0.1s'>ARRIVAL OF POWER BARGE 113 </h3>
          <img src='../resources/img/newsletter/img8.png' alt='PB113'></img>
          {/* <div className='row'>
            <div className='col'>
              <img src='../resources/img/newsletter/img8.png'></img>
            </div>
            <div className='col'>
              <img src='../resources/img/newsletter/img9.png'></img>
            </div>
          </div> */}
         
          <p className='history-content wow fadeInUp mt-3' data-wow-delay='0.1s'>
              Bilang tugon sa mga nararanasang malawakang pagkawala ng daloy ng kuryente sa lalawigan ng Marinduque lalo’t higit sa Feeder 1 at 3 dulot ng kakulangan sa suplay,
              ang pamunuan ng Marinduque Electric Cooperative, Inc. (MARELCO) sa pangunguna ng Acting General Manager, Engr. Gaudencio M. Sol, Jr. ay nakipag-ugnayan sa pamunuan
              ng National Power Corporation (NAPOCOR) upang mabigyang solusyon ang nasabing problema na kinakaharap ng lalawigan.  Ito ay tinugon ng NAPOCOR sa pamamagitan ng
              Power Barge 113. Hindi naging madali ang pagsasakatuparan ng pagdadala  ng naturang Power Barge sa probinsya ngunit dahil sa pagtutulungan ng pamunuan ng MARELCO 
              at NAPOCOR at sa agarang pagtugon ni Cong. Lord Allan Jay Q. Velasco, matagumpay na nadala ang Power Barge 113 noong Agosto 23, 2024 sa Buyabod Port, Sta. Cruz. 
              
              <button className='btn' type='button' data-toggle='collapse' data-target='#collapseTopic3' aria-expanded='false' aria-controls='collapseTopic3'>
              <p>Ipagpatuloy ang pagbabasa</p>
            </button>
          </p>
              <br/>
              <div id='collapseTopic3' className='collapse hide'>  
              <p className='history-content wow fadeInUp' data-wow-delay='0.1s'>
                Ang nasabing Power Barge ay pag-aari ng NAPOCOR at may itinakdang kapasidad na 1.8MW na inaasahang makatutulong sa pagpapabuti ng suplay ng kuryente sa buong 
                lalawigan. Bago tuluyang nagamit ang naturang Power Barge ay isinailalim ito sa load testing at commissioning kung kaya’t noong Setyembre 6, 2024 ay tuluyan na 
                itong idineklarang “operational” kasabay ng ginawang Switch-on Ceremony sa Brgy. Buyabod, Sta. Cruz.   
              </p>       
          </div>
           <br />
        </div>
      </div>

      <div className='container-history'>
        <div className='container box_1170'>
          <h3 className='text-heading history-title wow fadeInUp' data-wow-delay='0.1s'> TASK FORCE KAPATID: BAYANIHAN SA PAGPAPANUMBALIK NG LIWANAG SA GITNA NG KALAMIDAD </h3>
          <div className='row'>
            <div className='col'>
            <img src='../resources/img/newsletter/img5.png' alt='TASKFORCE KAPATID'></img>
            </div>
            <div className='col'>
            <img src='../resources/img/newsletter/img6.png' alt='TASKFORCE KAPATID'></img>
            </div>
            {/* <div className='col'>
            <img src='../resources/img/newsletter/img7.png'></img>
            </div> */}
          </div>
          
          <p className='history-content wow fadeInUp mt-2' data-wow-delay='0.1s'>
              Ang Pilipinas ay isang bansang madalas daanan ng kalamidad gaya ng bagyo, lindol, at iba pang natural na sakuna. Dahil dito, ang sektor ng enerhiya ay isa sa 
              pangunahing naapektuhan ng mga ganitong pangyayari. Isa sa mga mahalagang grupo na kumikilos upang agad maibalik ang kuryente sa mga apektadong lugar ay ang mga
              electric cooperatives sa pamamagitan ng Task Force Kapatid.
              <br/>
              <br/>
              Ang Task Force Kapatid ay isang inisyatibo ng mga electric cooperatives sa ilalim ng National Electrification Administration (NEA) at Philippine Rural Electric
              Cooperatives (PHILRECA) na binuo upang tumugon sa mga pangangailangan ng kuryente sa mga lugar na nasalanta ng kalamidad. Layunin nito na magsagawa ng 
              "bayanihan" sa pagitan ng mga electric cooperatives upang mabilis na maibalik ang mga linya ng kuryente at mapagaan ang kalagayan ng mga komunidad na lubhang 
              nangangailangan ng tulong.

            <button className='btn' type='button' data-toggle='collapse' data-target='#collapseTopic4' aria-expanded='false' aria-controls='collapseTopic4'>
              <p>Ipagpatuloy ang pagbabasa</p>
            </button>

          </p>
          {/* <br/> */}
          <br/>
          <div id='collapseTopic4' className='collapse hide'>  
          <p className='history-content wow fadeInUp' data-wow-delay='0.1s'>
              Kapag may kalamidad, agad na naglalabas ng abiso ang NEA upang humiling ng tulong mula sa iba't ibang electric cooperatives. Sa ilalim ng Task Force Kapatid, 
              ang mga electric cooperative mula sa mga  rehiyon na hindi apektado ay nagpapadala ng mga tauhan, kagamitan, at resources sa mga lugar na labis na napinsala. 
              Ang tulong na ito ay binubuo ng mga skilled linemen, technical engineers, at iba pang    personnel na may kakayahang mag-ayos ng mga linya ng kuryente.
              <br/>
              <br/>
              Ang nagdaang bagyong “Kristine” ay nagdulot ng malawak na pinsala sa maraming lugar sa bansa. Bagama’t naapektuhan ang Marinduque, naging mabilis ang MARELCO sa
              pagsasaayos ng mga linya at pagpanumbalik ng kuryente sa mga apektadong barangay sa loob ng halos isang linggo lamang. Dahil dito, nang humiling ng tulong ang 
              mga kapatid na electric cooperatives, agad tumugon ang pamunuan ng MARELCO sa pangunguna ni Engr.  Gaudencio M. Sol, Jr., Acting General Manager. Noong October
              28, 2024 ay bumiyahe ang Task Force MARELCO na binubuo nina Rodolfo B. Jabal, Jr., Ramil Motol, Elias Historillo, Anthony Majaba, Christian Orilla, at Daryl 
              Magculang upang tulungan ang Batangas I Electric Cooperative (BATELEC I) sa power restoration ng mga sakop na lugar nito. 
              <br/>
              <br/>
              Bagama’t malaki ang naitutulong ng Task Force MARELCO, hamon para sa kanila ang magtungo sa malayong bayan na  malayo sa pamilya. Hamon din ang kakulangan sa 
              suplay ng kagamitan, at mga panganib na kinakaharap habang nagtatrabaho. Gayunpaman, sa kabila ng mga ito, patuloy ang pagpupunyagi ng Task Force MARELCO upang 
              maisagawa ang kanilang tungkulin sa abot ng    kanilang makakaya. Sila ang tunay na bayani ng pailaw, mga lineman na Marinduqueño para sa mga Batangueño.
              <br/>
              <br/>
              Sadyang ang pagtutulungan ng mga electric cooperatives ay hindi lamang pagpapanumbalik ng kuryente  kundi pati na rin pagbabalik ng pag-asa para sa mas magandang
              kinabukasan ng bawat komunidad.
          </p>
          </div>
         </div>
      </div>

      <div className='container-history'>
        <div className='container box_1170'>
          <h3 className='text-heading history-title wow fadeInUp' data-wow-delay='0.1s'>BUWAN NG AGOSTO: PAGPUPUGAY SA ATING MGA BAYANI NG PAILAW  </h3>
          <img src='../resources/img/newsletter/img10.png' alt='LINEWORKERS'></img>
          <p className='history-content wow fadeInUp mt-2' data-wow-delay='0.1s'>
              Ang mga manggagawa ng Electric Cooperatives sa pangunguna ng mga lineworkers ay tinatawag na “Warriors of Light” dahil sa kanilang dedikasyon sa pagpapanatili ng kuryente sa lugar na  kanilang nasasakupan. Sila ang mga unang tumutugon tuwing may mga aberya o problema sa daloy ng kuryente, lalo na sa mga panahon ng kalamidad tulad ng bagyo, lindol, o iba pang sakuna. Ang kanilang trabaho ay madalas na delikado, at may malaking panganib na makuryente. 
           
              <button className='btn' type='button' data-toggle='collapse' data-target='#collapseTopic5' aria-expanded='false' aria-controls='collapseTopic5'>
              <p>Ipagpatuloy ang pagbabasa</p>
            </button>
          </p>
          <br/>
          <div id='collapseTopic5' className='collapse hide'>  
          <p className='history-content wow fadeInUp' data-wow-delay='0.1s'>
              Bilang pagkilala sa kanilang mahalagang kontribusyon sa ating lipunan, ang buwan ng Agosto ay inilaan upang kilalanin at pahalagahan ang mga  Lineworkers. Ito ay isang natatanging pagkakataon para ipakita  ang ating taos-pusong pasasalamat at suporta sa kanilang pagsisikap. Nitong nagdaang Agosto 5, 2024, ipinagdiwang ng MARELCO ang mga Lineworkers bilang paggunita sa Linemen Appreciation Day sa ilalim ng House Bill 472. Ang pagkilalang ito ay isang paalala ng kanilang sakripisyo at kabayanihan sa kabila ng mga pagsubok, at ang patuloy nating pasasalamat sa kanilang    serbisyo. 
              <br/><br/>
              <img src='../resources/img/newsletter/img11.png' alt='LINEWORKERS'></img>
              <br/>
              <br/>
              Tunay na hindi matatawaran ang pagsusumikap ng mga lineworkers upang maihatid ang Liwanag sa bawat sulok ng lalawigan ng Marinduque. Sa  kabila ng hamon ng kanilang trabaho—mula sa matinding init ng araw, malalakas na ulan, at panganib na dala ng kanilang gawain—hindi sila tumitigil sa pagbibigay-serbisyo sa kanilang mga kababayan. Anumang oras, araw man o gabi, handa silang rumesponde sa pangangailangan ng komunidad, lalo na sa mga panahong kritikal tulad ng kalamidad o emerhensiya.   
              <br/>
              <br/>
              Ang kanilang dedikasyon ay hindi lamang simpleng tungkulin kundi isang tunay na sakripisyo para masigurong walang tahanan, negosyo, o institusyon ang magigising sa dilim. Sila ang tahimik na mga bayani na nagsisiguro ng tuluy-tuloy na suplay ng kuryente, na siyang nagbibigay ng ginhawa at kaunlaran sa bawat mamamayan ng Marinduque. Ang kanilang walang pagod na paglilingkod ay patunay ng kanilang malasakit sa kapwa at pagmamahal sa kanilang lalawigan. Sa ganitong kadakilaan ng layunin, nararapat lamang silang pahalagahan at kilalanin bilang mga tagapaghatid ng   liwanag at pag-asa.  
          </p>
          </div>
        </div>
      </div>

      <div className='container-history'>
        <div className='container box_1170'>
          <h3 className='text-heading history-title wow fadeInUp' data-wow-delay='0.1s'>Pagtatanim ng Puno Isinagawa sa Bantay, Boac bilang Bahagi ng National Electrification Awareness Month</h3>
          <img src='../resources/img/newsletter/img12.png' alt='TREE PLANTING'></img>
          <div className='row'>
            <div className='col'>
            <img src='../resources/img/newsletter/img13.png 'alt='TREE PLANTING'></img>
            </div>
            <div className='col'>
            <img src='../resources/img/newsletter/img14.png' alt='TREE PLANTING'></img>
            </div>
            <div className='col'>
            <img src='../resources/img/newsletter/img15.png' alt='TREE PLANTING'></img>
            </div>
            <div className='col'>
            <img src='../resources/img/newsletter/img16.png' alt='TREE PLANTING'></img>
            </div>
            <div className='col'>
            <img src='../resources/img/newsletter/img17.png' alt='TREE PLANTING'></img>
            </div>
            <div className='col'>
            <img src='../resources/img/newsletter/img18.png' alt='TREE PLANTING'></img>
            </div>
          </div>
          <p className='history-content wow fadeInUp mt-3' data-wow-delay='0.1s'>
              Noong Agosto 22, 2024, isinagawa ang tree planting activities sa Brgy. Bantay, Boac bilang bahagi sa pagdiriwang ng National Electrification Awareness Month (NEAM)
              at hakbang sa pagbibigay-pugay sa kalikasan. Ang nasabing inisyatibo ay pinangunahan ng MARELCO Acting General Manager na si Engr. Gaudencio M. Sol, Jr., Board 
              President Dir. Christopher M. Morales, iba pang   miyembro ng Hunta Direktiba at mga kawani ng MARELCO. Kaagapay rin sa nasabing aktibidad ang mga piling opisyales 
              mula sa Department of Environment and Natural  Resources (DENR) at ang Sangguniang Barangay ng Bantay. Layunin ng nasabing aktibidad na palitan ang mga punong 
              naputol dahil sa pagpapatupad ng RA 11361 o mas kilala bilang Anti-Obstruction of Powerlines Act. 
              
              <button className='btn' type='button' data-toggle='collapse' data-target='#collapseTopic6' aria-expanded='false' aria-controls='collapseTopic6'>
              <p>Ipagpatuloy ang pagbabasa</p>
            </button>
          </p>
          <div id='collapseTopic6' className='collapse hide'> 
            <br/> 
            <p className='history-content wow fadeInUp' data-wow-delay='0.1s'>
                Ang naturang batas ay naglalayong panatilihing ligtas ang mga 
                linya ng kuryente mula sa anumang hadlang na maaaring magdulot ng panganib o hambalang sa daloy ng serbisyo ng elektrisidad na kadalasang dahil sa mga puno na 
                malapit sa linya. Dahil dito, marami na ring puno ang naputol kapalit ng layunin na mapabuti ang daloy ng kuryente kung kaya’t bahagi na ng taunang tungkulin ng 
                MARELCO ang magtanim ng puno. Ang gawaing ito ay hindi lamang dahil sa NEAM kundi isang konkretong hakbang na nagpapakita ng responsibilidad ng bawat isa na ibalik 
                ang biyaya ng kalikasan sa tuwing may nasasakripisyo para sa rehabilitasyon. Ang National Electrification Awareness Month ay ipinagdiriwang tuwing Agosto upang 
                bigyang-diin ang mahalagang papel ng elektripikasyon sa pagsulong ng kaunlaran sa bansa.    
            </p>
          </div>
        </div>
      </div>

      <div className='container-history'>
        <div className='container box_1170'>
          <h3 className='text-heading history-title wow fadeInUp' data-wow-delay='0.1s'>MARELCO Brings its Corporate Social Responsibility to Duyay  Elementary School with Feeding Program for National Cooperative Month </h3>
          <img src='../resources/img/newsletter/img19.png' alt='CSR'></img>
          <p className='history-content wow fadeInUp mt-3' data-wow-delay='0.1s'>
          In an inspiring display of corporate social responsibility, MARELCO conducted a feeding program for the students of Duyay Elementary School on October 10, 2024 in celebration of the National Cooperative Month. The initiative, which was Topic of MARELCO’s ongoing commitment to community development, aimed to address food security, promote healthy habits, and reinforce its dedication to support communities.
          
          <button className='btn' type='button' data-toggle='collapse' data-target='#collapseTopic7' aria-expanded='false' aria-controls='collapseTopic7'>
              <p>Read More</p>
          </button>
        </p>
        <br/>
        <div id='collapseTopic7' className='collapse hide'> 
          <p className='history-content wow fadeInUp' data-wow-delay='0.1s'>
          <div className='row'>
            <div className='col'>
              <img src='../resources/img/newsletter/img21.png' alt='CSR'></img>
            </div>
            <div className='col'>
              <img src='../resources/img/newsletter/img22.png' alt='CSR'></img>
            </div>
          </div>
          <br />
          
            The feeding program provided nutritious “lugaw” and drinks to over 100 students of the said school. As counterpart, the school administration headed by Ms. Arline M. Mayo, prepared a short program showcasing the pupils’ talent. According to Ms. Mayo, the activity serves as a reminder to the students to be a bridge in extending blessings to others and to draw inspiration from it, so that one day, when they have the means, they can carry out similar acts.
            <br />
            <br />
            <div className='row'>
              <div className='col'>
                <img src='../resources/img/newsletter/img20.png' alt='CSR'></img>
              </div>
            </div>
            
            <br />
            MARELCO regularly organize such programs as part of its corporate social responsibility (CSR) agenda. Being a cooperative, it aims to strengthen its role in local communities by continuously supporting initiatives that promote health, education, and sustainable community development.
            <br /><br />
            The feeding program is a testament to MARELCO’s commitment to not only sustain light in every home but also empower communities through meaningful acts of service. As National Cooperative Month calls on all members of society to work together for collective progress, MARELCO’s outreach stands as a beacon of hope and goodwill, bringing warmth, nourishment, and a brighter future to the children of Duyay Elementary School.   
            </p>
          </div>
        </div>
      </div>

      



    </section>

         

     
    </>
  )


}

export default Newsletter